import React, { FunctionComponent, useCallback } from "react"
import { useFirebaseAuth } from "../utils/auth"
import { PageDrawer } from "../components/Common/drawer"
import { Login } from "../components/login"
import { Layout } from "../components/Layout"

const Index: FunctionComponent = () => {
  let body = <Login />
  const [ user, claim, loading ] = useFirebaseAuth()
  if (!loading) {
    if (user) {
      if (claim.permission === 0) {
        body =
          <>
            <PageDrawer />
            <p>左のメニューより、操作を選択して下さい</p>
          </>
      } else {
        body = <p>操作権限がありません</p>
      }
    }
  }
  return <Layout>{body}</Layout>
}

export default Index
